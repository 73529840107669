@font-face {
  font-family: SansationLight;
  src: url("./assets/fonts/sansation_light-webfont.woff") format("opentype");
}

html {
  height: 100%;
}

body {
  height: 100%;
  padding: 10px;
  padding-top: 90px;
  padding-bottom: 60px;
  font-family: SansationLight, Arial, Helvetica, sans-serif;
}

.shadow-box {
  padding: 7px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
  margin-bottom: 15px;
}

.shadow-box .h3 {
  font-weight: bolder;
}

.pointer {
  cursor: pointer;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, .15);
}

.dropdown-menu-dark .dropdown-item {
  color: #fff;
}

.dropdown-menu-dark .dropdown-item:hover {
  background-color: #6c757d;
}

cdk-virtual-scroll-viewport {
  height: 100%;
}

.gallery-image {
  width: 200px;
}

.gallery-item-container {
  flex-direction: column;
}

.gallery-image-container {
  max-width: 400px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .gallery-image {
    width: 300px;
  }

  .gallery-item-container {
    flex-direction: column;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .gallery-image {
    width: 300px;
  }

  .gallery-item-container {
    flex-direction: row;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .gallery-image {
    width: 350px;
  }

  .gallery-item-container {
    flex-direction: row;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .gallery-image {
    width: 350px;
  }

  .gallery-item-container {
    flex-direction: row;
  }
}
